.about_us_content {
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-left: 10%;
}

.about_us_txt {
  text-align: center;
  vertical-align: middle;
  float: left;
  display: inline-block;
  width: 35%;
}

.about_us_img {
  float: right;
  width: 45%;
  padding-left: 180px;

  img {
    float: right;
    width: 100%;
  }
}

//Mobile
@media screen and (max-width: 1024px) {
  .about_us_content {
    display: block;
    margin-left: 0;
  }

  .about_us_txt {
    clear: both;
    width: 100%;
  }

  .about_us_img {
    clear: both;
    margin-top: 50px;
    width: 100%;
  }
}