.footer {
  height: 525px;

  overflow: hidden;
  display: flex;

  background-color: #000000;

  margin-top: -175px;
}

.contact {
  color: $white;

  padding-left: 100px;
  padding-top: 200px;
}

.store {
  color: $white;

  float: left;
  width: 20%;

  padding-left: 200px;
  padding-top: 200px;
}

.garage {
  color: $white;

  float: right;
  width: 20%;

  padding-top: 200px;
  padding-right: 50px;
}

.week_day {
  width: 150px;

  a {
    &:link {
      color: $white;
    }
    &:visited {
      color: $white;
    }
  }
}

.footer_list {
  font-style: italic;
}

.footer_paragraph {
  font-style: italic;
}

@media screen and (min-width: 1600px) {
  .store {
    padding-left: 600px;
  }
}

//ipad
@media screen and (max-width: 1300px) {
  .footer {
    display: block;
    height: 750px;
  }

  .contact {
    padding-left: 20px;
  }

  .store {
    width: 40%;
    padding-top: 40px;
    padding-left: 20px;
  }

  .garage {
    width: 40%;
    padding-top: 40px;
  }
}

//mobile
@media screen and (max-width: 640px) {
  .footer {
    display: block;
    height: 1050px;
  }

  .contact {
    padding-left: 20px;
  }

  .store {
    clear: both;
    width: 100%;
    padding-top: 40px;
    padding-left: 20px;
  }

  .garage {
    clear: both;
    float: none;
    width: 100%;
    padding-top: 20px;
    padding-left: 20px;
  }
}