.partners_txt {
  line-height: 1.5;
  font-weight: 400;
  letter-spacing: 0.00938em;
}

.indoor_wrapper {
  margin-top: 5rem;
  overflow: hidden;
  display: flex;
}

.indoor_txt {
  float: left;
  display: inline-block;
  width: 45%;
  margin-right: 10rem;
}

.indoor_img {
  display: inline-block;
  float: right;
}

.logos_title {
  margin-top: 45px;
}

.logos_wrapper {
  margin-top: 45px;

  img {
    width: 12%;
    margin-right: 35px;
  }
}

@media screen and (max-width: 1024px) {
  .indoor_wrapper {
    display: block;
  }

  .indoor_txt {
    clear: both;
    width: 100%;
    margin-right: 0;
  }

  .indoor_img {
    clear: both;
    float: none;
    display: block;
    margin-right: auto;
    margin-left: auto;
    width: 8em;
  }

  .logos_wrapper {

    img {
      width: 30%;
      margin-right: 10px;
    }
  }
}