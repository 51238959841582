.aanbiedingen_wrapper {
  overflow: hidden;

  .aanbieding_card {
    border: 1px solid black;
    min-height: 500px;
  }
}

.aanbieding_1 {
  float: left;
  width: 25%;
  padding: 10px;
  margin-right: 3rem;
}

.aanbieding_2 {
  width: 25%;
  padding: 10px;
  overflow: hidden;
}

.aanbieding_txt {
  line-height: 1.5;
  font-weight: 400;
  letter-spacing: 0.00938em;
}

.aanbieding_img {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .aanbieding_1 {
    width: 90%;
    margin-bottom: 10%;
  }

  .aanbieding_2 {
    width: 90%;
  }
}