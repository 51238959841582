.on_image {
  background-color: transparent !important;
  box-shadow: none !important;

  .menu_item {
    color: #000000 !important;
  }
}

.home_scroll {
  cursor: pointer;
  width: 20%;
}

.past_image {
  background-color: #FFFFFF !important;
}

.navigation_logo {
  width: 100%;
}

.menu_items {
  margin-left: auto;

  .menu_item {
    font-family: 'Raleway', serif;
    font-weight: 500;
    text-transform: uppercase;
    color: $textcolor;

    cursor: pointer;

    margin-right: 50px;

    &:last-child {
      margin-right: 0;
    }
  }
}

//mobile styling
.menu_icon_black {
  color: #000000;
}

@media screen and (max-width: 1024px) {
  .navigation_logo {
    width: 200%;
  }

  .navigation_logo_mobilenav {
    width: 450%;
    padding: 25px;
    margin-top: 25px;
  }

  .mobile_menu_list {
    .close_icon {
      margin-left: 85%;
      margin-top: 15px;
    }

    .MuiDrawer-paper {
      width: 100%;
    }
  }

  .mobile_nav {
    display: block;
  }

  .desktop_nav {
    display: none;
  }

  .menu_item {
    font-size: 1.5rem;
    padding: 35px;
    text-align: center;
    cursor: pointer;
  }
}

@media screen and (min-width: 1025px) {
  .mobile_nav {
    display: none;
  }
}