.service_card {
  display: inline-block;
  background-color: $nbred !important;

  color: $white !important;
  text-align: center;

  width: 45%;
  height: 180px;
  margin: 30px;

  -webkit-box-shadow: 6px 6px 18px 0px rgba(0,0,0,0.75) !important;
  -moz-box-shadow: 6px 6px 18px 0px rgba(0,0,0,0.75) !important;
  box-shadow: 6px 6px 18px 0px rgba(0,0,0,0.75) !important;

  h2 {
    font-family: 'Raleway', serif;
    text-transform: uppercase;
    font-size: 1.2rem;
  }

  .service_icon {
    padding-bottom: 25px;
  }
}

.service_extra_info {
  font-style: italic;
  margin-left: 25px;
}

@media screen and (max-width: 1300px) {
  .service_card {
    width: 43%;
  }
}

@media screen and (max-width: 1024px) {
  .service_card {
    width: 95%;
    margin: 10px;

    h2 {
      font-size: 1rem;
    }
  }

  .service_extra_info {
    margin-left: 10px;
    font-size: .8rem;
  }
}