body, html {
  font-family: 'Raleway', serif;

  margin: 0;
  height: 100%;
}

.section {
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 100px;
}

.section-title {
  font-family: 'Montserrat', serif;
  font-weight: 900;
  text-transform: uppercase;

  margin-bottom: 50px;
}

//Mobile
@media screen and (max-width: 1224px) {
  .section {
    margin-left: 20px;
    margin-right: 20px;
  }

  .section-title {
    font-size: 1.8rem;

    margin-bottom: 20px;
  }
}