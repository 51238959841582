.bootfitting_intro_title {
  font-family: 'Raleway', serif !important;
  font-weight: 700 !important;
}

.bootfitting_intro_txt {
  font-family: 'Raleway', serif !important;
}

.bootfitting_card {
  width: 45%;
  margin: 15px;
  display: inline-grid;
  padding: 15px;

  .bootfitting_vid {
    width: 100%;
    height: 300px;
    margin-bottom: 25px;
  }

  .bootfitting_vid_noshow {
    display: none;
  }

  .bootfitting_title {
    font-family: 'Raleway', serif;
    font-weight: 700;

    margin-top: 25px;
    margin-bottom: 15px;
  }

  p {
    font-family: 'Raleway', serif;
  }
}

@media screen and (max-width: 1300px) {
  .bootfitting_card {
    width: 43%;
  }
}

@media screen and (max-width: 1024px) {
  .bootfitting_card {
    width: 100%;
    margin: 0;
    padding-left: 0;
    padding-top: 35px;
    padding-bottom: 25px;

    .bootfitting_vid {
      height: 300px;
    }
  }
}