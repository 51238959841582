.home_slide_container {
  .nav {
    display: none !important;
  }

  .each_slide {
    div {
      height: 100vh;
      background-size: cover;
      background-position: 60%;
    }
  }
}