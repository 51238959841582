.info_content {
  overflow: hidden;
  display: flex;
  margin-bottom: 100px;
}

.info_icon_wrapper {
  float: left;
  width: 35%;
  text-align: center;

  .info_icon {
    font-size: 18rem;
    color: $nbred;
  }
}

.info_text {
  float: right;
  width: 65%;

  p {
    margin-top: 15px;
    font-family: 'Raleway', serif;
    font-weight: 700;
    font-size: 1.5rem;
  }
}

.google_maps {
  width: 100%;
  height: 800px;
}

@media screen and (max-width: 1024px) {
  .info_content {
    display: block;
    margin-bottom: 50px;
  }

  .info_icon_wrapper {
    clear: both;
    width: 100%;

    .info_icon {
      font-size: 10rem;
      margin-top: 0;
    }
  }

  .info_text {
    clear: both;
    width: 100%;

    p {
      font-size: 1rem;
    }
  }

  .google_maps {
    height: 300px;
  }
}